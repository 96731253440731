<template>
  <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->
  <div v-if="isLoading" class="col-md-12" style="text-align: center;padding-top: 255px;">
    <pm-ProgressSpinner />
    <p>Aguarde...</p>
  </div>
  <section v-if="!isLoading">
    <div
      class="p-fluid formgrid grid justify-content-between"
      style="margin-top: 20px"
    >
      <div class="field col-12 md:col-6 p-0">
        <div class="md:col-6 lg:col-5 xl:col-4" v-if="ano == null">
          <pm-Button
            v-if="acesso.cadastrar"
            label="Iniciar Ano Letivo"
            @click="start()"
            class="p-button-success"
            :loading="isLoading == true"
            icon="pi pi-play"
            iconPos="left"
          />
        </div>

        <div
          class="flex align-items-center md:col-12 lg:col-12 xl:col-12"
          v-if="ano != null"
        >
          <pm-Dropdown
            dropdown
            placeholder="Selecione o Calendário "
            v-model="calSelec"
            :options="todosCalendarios"
            @change="selectCal()"
            optionLabel="nome"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.nome }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>

            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.nome }}</div>
              </div>
            </template>
            <template #empty>
              <div class="country-item">
                <div>Nenhum Calendário Disponível</div>
              </div>
            </template>
          </pm-Dropdown>
          <div>
            <pm-Button
              class="p-button-rounded p-button-secondary p-button-outlined ml-3"
              icon="pi pi-filter"
              v-tooltip.bottom="{
                value: 'Filtrar calendários',
                class: 'custom-message',
              }"
              style="font-size: 1.25rem"
              @click="actionModal(6)"
            ></pm-Button>
            <pm-Button
              class="p-button-rounded p-button-secondary p-button-outlined ml-3"
              icon="pi pi-cog"
              v-tooltip.bottom="{
                value: 'Configurações gerais de todos calendários',
                class: 'custom-message',
              }"
              style="font-size: 1.25rem"
              @click="paginaConfig()"
            ></pm-Button>
            <pm-Button
              v-if="anoSitucao == 1"
              class="p-button-rounded p-button-secondary p-button-outlined ml-3"
              icon="pi pi-plus"
              v-tooltip.bottom="{
                value: 'Criar novo calendário',
                class: 'custom-message',
              }"
              style="font-size: 1.25rem"
              @click="actionModal(1)"
            ></pm-Button>       
          </div>
    
        </div>
      </div>
     
      <!-- <div v-if="this.item[0].situacao == 0" class="field col-12 md:col-5 lg:col-3 xl:col-3" style="background-color: #f8f9fa; padding: 10px; border-radius: 5px;">
        <p style="font-weight: bold; color: #154EC1; border: 2px solid blue;padding: 14px; border-radius: 8px;">
          Habilitar alterações no ano letivo encerrado
          <pm-InputSwitch class="small-switch" v-model="anoSituacaoAlterar"  @change="atualizarAnoSituacao()" style="vertical-align: middle !important; margin-left: 10px;float: right;"/>
        </p>
        <br>
        <p style="background-color: #FFDF6D; padding: 14px; border-radius: 8px;">
          Atenção! Ao habilitar, o sistema fica aberto a alterações irreversíveis.          
        </p>
      </div> -->

      <div class="field col-12 md:col-4 lg:col-3 xl:col-2 ">
        <!-- <pm-Button
          v-if="acesso.editar && this.item[0].situacao == 1"
          :label="'Encerrar ano letivo'"
          title="Fechar Todos Calendários"
          icon="pi pi-times"
          class="p-button-danger mt-3"
          iconPos="left"
          @click="actionModal(4)"
        /> -->
        <div
          v-if="configuracao.length != 0 && configuracao.autorizado != 1"
          class="mt-1"
        >
          <pm-Button
            style="float: right"
            class=""
            label="Ativar Configuração"
            title="Ativar Configuração"
            @click="ativarConfig"
          ></pm-Button>
        </div>
        <!-- <div
          v-if="
            configuracao.length != 0 &&
              configuracao.autorizado == 1 &&
              user.data.funcao == 1
          "
          class="mt-1"
        >
          <pm-Button
            style="float: right"
            class=""
            icon="pi pi-play"
            label="Permitir alterar configurações"
            title="Permitir alterar configurações"
            @click="permitirAtivarConfig"
          ></pm-Button>
        </div> -->
      </div>
    </div>

    <div v-if="calSelec">
      <h2 v-if="calSelec.situacao === 0" class="titulo-links-escolha">
        {{ calSelec.nome }} (fechado)
      </h2>
      <h2 v-else class="titulo-links-escolha">{{ calSelec.nome }}</h2>
    </div>

    <div v-if="calSelec" class="pm-card">
      <br />
      <div class="row">
        <div class="col-12 flex justify-content-between">
          <div class="col-md-10 legenda">
            <h4>Legenda</h4>
          </div>

          <div class="flex col-md-1">
            <div>
              <pm-Button
                disabled
                v-if="acesso.editar"
                class="p-button-secondary p-button-rounded p-button-text"
                v-tooltip.top="{
                  value: 'Fazer a ligação do calendário com segmentos',
                  class: 'custom-message',
                }"
                icon="pi pi-link"
                style="font-size: 1.25rem"
                @click="actionModal(3)"
              />
            </div>

            <div>
              <pm-Button
                disabled
                v-if="acesso.editar"
                class="p-button-secondary p-button-rounded p-button-text"
                v-tooltip.top="{
                  value: 'Configuração deste calendário',
                  class: 'custom-message',
                }"
                icon="pi pi-cog"
                style="font-size: 1.25rem"
                @click="actionModal(2)"
              />
            </div>

            <div>
              <pm-Button
                disabled
                v-if="acesso.editar"
                class="p-button-secondary p-button-rounded p-button-text"
                v-tooltip.top="{
                  value: 'Excluir este calendário',
                  class: 'custom-message',
                }"
                icon="pi pi-times"
                style="font-size: 1.25rem"
                @click="actionModal(7)"
              />
            </div>
          </div>
        </div>

        <div class="col-md-12 legenda-comp row" style="display: inherit">
          <div class="legenda-item col-2">
            <div class="col-md-1" style="width: 16px; padding-top: 5px">
              <div
                style="width: 16px; height: 16px; background-color: #2279e5"
              ></div>
            </div>
            <div class="col-md-11">
              <h5 style="margin-left: 5px; margin-top: -9px;">
                Dias letivos

                <label v-if="calSelec" class="numb-dias-letivos-total" title="Total de Dias Letivos do Ano">
                  {{
                    calSelec["numeros"]["janeiro"] + calSelec["numeros"]["fevereiro"] + calSelec["numeros"]["marco"] +
                      calSelec["numeros"]["abril"] + calSelec["numeros"]["maio"] + calSelec["numeros"]["junho"] +
                      calSelec["numeros"]["julho"] + calSelec["numeros"]["agosto"] + calSelec["numeros"]["setembro"] +
                      calSelec["numeros"]["outubro"] + calSelec["numeros"]["novembro"] + calSelec["numeros"]["dezembro"]
                  }}

                </label>
              </h5>
            </div>
          </div>
          <div class="legenda-item col-2" v-for="n in this.legenda" :key="n.periodo">
            <div class="col-md-1" style="width: 16px; padding-top: 5px">
              <div
                :style="
                  'width:16px;height:16px;background-color:#' + n.colorpicker
                "
              ></div>
            </div>
            <div class="col-md-11">
              <h5 style="margin-left: 5px">{{ n.periodo }}</h5>
            </div>
          </div>
        </div>

        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Janeiro"
          mes_marcar="janeiro"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Fevereiro"
          mes_marcar="fevereiro"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Março"
          mes_marcar="marco"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Abril"
          mes_marcar="abril"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Maio"
          mes_marcar="maio"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Junho"
          mes_marcar="junho"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Julho"
          mes_marcar="julho"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Agosto"
          mes_marcar="agosto"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Setembro"
          mes_marcar="setembro"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Outubro"
          mes_marcar="outubro"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Novembro"
          mes_marcar="novembro"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Dezembro"
          mes_marcar="dezembro"
          @marcar="marcar"
          :ativar_calendario="ativar_calendario"
          :item="calSelec"
          :acesso="acesso"
        />

        <div
          v-if="calSelec.situacao === 1"
          class="flex col-3 justify-content-end"
        >
          <!--<pm-Button
            v-if="acesso.editar"
            label="Fechar Calendário"
            class="p-button-danger col-md-12 col-xl-12"
            iconPos="left"
            @click="actionModal(5)"
          />-->
        </div>
      </div>
    </div>

    <pm-Dialog
      header="Filtrar calendários exibidos"
      v-model:visible="showDialog6"
      :breakpoints="{ '500px': '75vw', '400px': '90vw' }"
      :style="{ width: '50vw' }"
      :maximizable="true"
    >
      <pm-RadioButton
        name="Mostrar todos os calendários"
        value="1"
        v-model="filtroDoCalendario"
        @change="filtrarCalendarios"
      />
      &nbsp;
      <label for="1">Mostrar todos os calendários</label>
      &nbsp;
      <pm-RadioButton
        name="Mostrar apenas os ativos"
        value="2"
        v-model="filtroDoCalendario"
        @change="filtrarCalendarios"
      />
      &nbsp;
      <label for="2">Mostrar apenas os ativos</label>
      &nbsp;
      <pm-RadioButton
        name="Mostrar apenas os desativados"
        value="3"
        v-model="filtroDoCalendario"
        @change="filtrarCalendarios"
      />
      &nbsp;
      <label for="3">Mostrar apenas os desativados</label>
      &nbsp;

      <template #footer>
        <pm-Button
          label="Fechar"
          class="p-button-danger"
          icon=""
          @click="actionModal(6)"
        />
      </template>
    </pm-Dialog>

    <pm-Dialog
      header="Criar Novo Calendário"
      v-model:visible="showDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '20vw' }"
      :maximizable="true"
    >
      <span class="p-float-label mt-5">
        <pm-InputText
          type="text"
          v-model="novoCal.nome"
          class="col-12 md:col-12 lg:col-8"
        ></pm-InputText>
        <label for="">Nome</label>
      </span>
      <span class="p-float-label mt-5">
        <pm-InputText
          type="number"
          v-model="novoCal.ano"
          class="col-12 md:col-12 lg:col-8"
        ></pm-InputText>
        <label for="">Ano</label>
      </span>

      <template #footer>
        <pm-Button
          label="Fechar"
          class="p-button-danger"
          icon=""
          @click="actionModal(1)"
        />
        <pm-Button label="Confirmar" icon="" @click="criarCal(novoCal)" />
      </template>
    </pm-Dialog>

    <pm-Dialog
      header="Configuração do Calendário"
      v-model:visible="showDialog2"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '70vw' }"
      :maximizable="true"
    >
      <span class="p-float-label mt-5">
        <pm-InputText
          type="text"
          v-model="calSelec.nome"
          class="col-12 md:col-4 lg:col-3"
          :disabled="disabled"
        ></pm-InputText>
        <label for="">Nome do Calendário</label>
      </span>

      <div v-if="!jaDefinidoConfiguracoes">
        <br>
        <h6>É do tipo EJA?</h6>
        <pm-InputSwitch v-model="ehDoTipoEja"  @change="ejaInput"/>
        <div class="row field col-12 md:col-4" v-if="ehDoTipoEja">
          <div class="field-radiobutton" style="margin-top: 22px">
            <label>Selecione o semestre?</label> &nbsp;&nbsp;
            <pm-RadioButton name="1"
              value="1"
              v-model="semestreSelecionado"
              @change="apenasUmSemestre(1)"
            />
            <label>1 Semestre</label>
            <pm-RadioButton
              name="2"
              value="2"
              style="margin-left: 7px"
              v-model="semestreSelecionado"
              @change="apenasUmSemestre(2)"
            />
            <label>2 Semestre</label>
          </div>
        </div>
      </div>

      <pm-Panel header="Períodos do Ano" class="col-md-12 mt-5">
        <div class="field col-12">
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-3" v-if="!ehDoTipoEja || (ehDoTipoEja && semestreSelecionado == 1)">
              <label>1° {{ configuracao.tipo_ano_nome_exib }}: </label>
              <div>
                Início:
                <pm-Calendar
                  :disabled="disabled"
                  v-model="configuracao.b1_inicio"
                  dateFormat="dd/mm/yy"
                  :showIcon="true"
                  @date-select="
                    verificarSeEhLetivo(
                      this.configuracao.b1_inicio,
                      1,
                      'inicio'
                    )
                  "
                  :class="{ 'p-invalid': validationErrors.b1_inicio }"
                />
                <small v-show="validationErrors.b1_inicio" class="p-error"
                >O dia do mês selecionado não é letivo!</small
                >
              </div>
              <div>
                Final:
                <pm-Calendar
                  :disabled="disabled"
                  v-model="configuracao.b1_final"
                  dateFormat="dd/mm/yy"
                  :showIcon="true"
                  @date-select="
                    verificarSeEhLetivo(this.configuracao.b1_final, 1, 'final')
                  "
                  :class="{ 'p-invalid': validationErrors.b1_final }"
                />
                <small v-show="validationErrors.b1_final" class="p-error"
                >O dia do mês selecionado não é letivo!</small
                >
                <small v-show="!confiValidation.finalUmOk" class="p-error"
                >A data de final deve ser posterior a data de início!</small
                >
              </div>
              <label
              >Selecione uma cor para o
                {{ configuracao.tipo_ano_nome_exib }}:</label
              >
              &nbsp;
              <pm-ColorPicker
                :disabled="disabled"
                style="padding-top: 14px"
                v-model="configuracao.colorpicker_1b"
                :inline="false"
              />
            </div>
            <div class="field col-12 md:col-3" v-if="!ehDoTipoEja || (ehDoTipoEja && semestreSelecionado == 1)">
              <label>2° {{ configuracao.tipo_ano_nome_exib }}: </label>
              <div>
                Início:
                <pm-Calendar
                  :disabled="disabled"
                  v-model="configuracao.b2_inicio"
                  dateFormat="dd/mm/yy"
                  :showIcon="true"
                  @date-select="
                    verificarSeEhLetivo(
                      this.configuracao.b2_inicio,
                      2,
                      'inicio'
                    )
                  "
                  :class="{ 'p-invalid': validationErrors.b2_inicio }"
                />
                <small v-show="validationErrors.b2_inicio" class="p-error"
                >O dia do mês selecionado não é letivo!</small
                >
                <small
                  v-show="confiValidation.segundaSelecaoInicioOk == 0"
                  class="p-error"
                >O mês ou dia selecionado precisa ser maior ao do período
                  anterior!</small
                >
              </div>
              <div>
                Final:
                <pm-Calendar
                  :disabled="disabled"
                  v-model="configuracao.b2_final"
                  dateFormat="dd/mm/yy"
                  :showIcon="true"
                  @date-select="
                    verificarSeEhLetivo(this.configuracao.b2_final, 2, 'final')
                  "
                  :class="{ 'p-invalid': validationErrors.b2_final }"
                />
                <small v-show="validationErrors.b2_final" class="p-error"
                >O dia do mês selecionado não é letivo!</small
                >
                <small v-show="!confiValidation.finalDoisOk" class="p-error"
                >A data de final deve ser posterior a data de início!</small
                >
                <small
                  v-show="
                    confiValidation.segundaSelecaoFinalOk == 0 &&
                      confiValidation.finalDoisOk
                  "
                  class="p-error"
                >O mês ou dia selecionado precisa ser maior ao do período
                  anterior!</small
                >
              </div>
              <label
              >Selecione uma cor para o
                {{ configuracao.tipo_ano_nome_exib }}:</label
              >
              &nbsp;
              <pm-ColorPicker
                :disabled="disabled"
                style="padding-top: 14px"
                v-model="configuracao.colorpicker_2b"
                :inline="false"
              />
            </div>
            <div class="field col-12 md:col-3" v-if="!ehDoTipoEja || (ehDoTipoEja && semestreSelecionado == 2)">
              <label>3° {{ configuracao.tipo_ano_nome_exib }}: </label>
              <div>
                Início:
                <pm-Calendar
                  :disabled="disabled"
                  v-model="configuracao.b3_inicio"
                  dateFormat="dd/mm/yy"
                  :showIcon="true"
                  @date-select="
                    verificarSeEhLetivo(
                      this.configuracao.b3_inicio,
                      3,
                      'inicio'
                    )
                  "
                  :class="{ 'p-invalid': validationErrors.b3_inicio }"
                />
                <small v-show="validationErrors.b3_inicio" class="p-error"
                >O dia do mês selecionado não é letivo!</small
                >
                <small
                  v-show="
                    confiValidation.terceiraSelecaoInicioOk == 0 &&
                      !validationErrors.b3_inicio
                  "
                  class="p-error"
                >O mês ou dia selecionado precisa ser maior ao dos períodos
                  anteriores!</small
                >
              </div>
              <div>
                Final:
                <pm-Calendar
                  :disabled="disabled"
                  v-model="configuracao.b3_final"
                  dateFormat="dd/mm/yy"
                  :showIcon="true"
                  @date-select="
                    verificarSeEhLetivo(this.configuracao.b3_final, 3, 'final')
                  "
                  :class="{ 'p-invalid': validationErrors.b3_final }"
                />
                <small v-show="validationErrors.b3_final" class="p-error"
                >O dia do mês selecionado não é letivo!</small
                >
                <small v-show="!confiValidation.finalTresOk" class="p-error"
                >A data de final deve ser posterior a data de início!</small
                >
                <small
                  v-show="
                    confiValidation.terceiraSelecaoFinalOk == 0 &&
                      confiValidation.finalTresOk &&
                      !validationErrors.b3_inicio
                  "
                  class="p-error"
                >O mês ou dia selecionado precisa ser maior ao dos períodos
                  anteriores!</small
                >
              </div>
              <label
              >Selecione uma cor para o
                {{ configuracao.tipo_ano_nome_exib }}:</label
              >
              &nbsp;
              <pm-ColorPicker
                :disabled="disabled"
                style="padding-top: 14px"
                v-model="configuracao.colorpicker_3b"
                :inline="false"
              />
            </div>
            <div class="field col-12 md:col-3" v-if="!ehDoTipoEja || (ehDoTipoEja && semestreSelecionado == 2)">
              <label>4° {{ configuracao.tipo_ano_nome_exib }}: </label>
              <div>
                Início:
                <pm-Calendar
                  :disabled="disabled"
                  v-model="configuracao.b4_inicio"
                  dateFormat="dd/mm/yy"
                  :showIcon="true"
                  @date-select="
                    verificarSeEhLetivo(
                      this.configuracao.b4_inicio,
                      4,
                      'inicio'
                    )
                  "
                  :class="{ 'p-invalid': validationErrors.b4_inicio }"
                />
                <small v-show="validationErrors.b4_inicio" class="p-error"
                >O dia do mês selecionado não é letivo!</small
                >
                <small
                  v-show="
                    confiValidation.QuartaSelecaoInicioOk == 0 &&
                      !validationErrors.b4_inicio
                  "
                  class="p-error"
                >O mês ou dia selecionado precisa ser maior ao dos períodos
                  anteriores!</small
                >
              </div>
              <div>
                Final:
                <pm-Calendar
                  :disabled="disabled"
                  v-model="configuracao.b4_final"
                  dateFormat="dd/mm/yy"
                  :showIcon="true"
                  @date-select="
                    verificarSeEhLetivo(this.configuracao.b4_final, 4, 'final')
                  "
                  :class="{ 'p-invalid': validationErrors.b4_final }"
                />
                <small v-show="validationErrors.b4_final" class="p-error"
                >O dia do mês selecionado não é letivo!</small
                >
                <small v-show="!confiValidation.finalQuatroOk" class="p-error"
                >A data de final deve ser posterior a data de início!</small
                >
                <small
                  v-show="
                    confiValidation.QuartaSelecaoFinalOk == 0 &&
                      confiValidation.finalQuatroOk &&
                      !validationErrors.b4_final
                  "
                  class="p-error"
                >O mês ou dia selecionado precisa ser maior ao dos períodos
                  anteriores!</small
                >
              </div>
              <label
              >Selecione uma cor para o
                {{ configuracao.tipo_ano_nome_exib }}:</label
              >
              &nbsp;
              <pm-ColorPicker
                :disabled="disabled"
                style="padding-top: 14px"
                v-model="configuracao.colorpicker_4b"
                :inline="false"
              />
            </div>
          </div>
        </div>
      </pm-Panel>

      <template #footer>
        <pm-Button
          label="Fechar"
          class="p-button-danger"
          icon="pi pi-times-circle"
          @click="actionModal(2)"
        />
        <pm-Button
          :disabled="disabled"
          label="Confirmar"
          icon="pi pi-save"
          @click="configCal(novaConfigCal)"
        />
      </template>
    </pm-Dialog>
    <pm-Dialog
      header="Ligar Segmentos ao Calendário"
      v-model:visible="showDialog3"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '70vw' }"
      :maximizable="true"
    >
      <div class="grid">
        <div
          v-for="seg of segmentos"
          :key="seg.id"
          class="field-checkbox md:col-3"
        >
          <pm-Checkbox
            :disabled="disabled"
            :inputId="seg.id"
            name="seg"
            :value="seg"
            v-model="listaSegmentos"
          />
          <label :for="seg.id">{{ seg.nome }}</label>
        </div>
      </div>

      <template #footer>
        <pm-Button
          label="Fechar"
          class="p-button-danger"
          @click="actionModal(3)"
        />
        <pm-Button label="Confirmar" :disabled="disabled" @click="linkarCal" />
      </template>
    </pm-Dialog>

    <pm-Dialog
      header="Encerrar ano letivo."
      v-model:visible="showDialog4"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :maximizable="true"
    >

      <div>
        <span>
          Ao encerrar o ano letivo, não será possível realizar qualquer tipo de alteração para o ano {{TheAno}}:
        </span>
        <ul>
          <li>- Ao confirmar, um novo calendário será criado para o ano seguinte.</li>
          <li>- Você será desconectado para reinserir os seus dados de autenticação.</li>
        </ul>
      </div>

      <div>
        <span
        >Deseja encerrar o ano letivo?
          <b style="color: red">*(Essa ação não terá retorno)</b></span
        >
      </div>
      <template #footer>
        <pm-Button
          label="Fechar"
          @click="actionModal(4)"
          class="p-button-danger"
        />
        <pm-Button label="Confirmar" @click="fecharTodos()" />
      </template>
    </pm-Dialog>

    <pm-Dialog
      v-if="calSelec"
      :header="'Fechar ' + calSelec.nome"
      v-model:visible="showDialog5"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :maximizable="true"
    >
      <div>
        <span
        >Deseja fechar o calendário: {{ calSelec.nome }}?
          <b style="color: red">*(Essa ação não terá retorno)</b></span
        >
      </div>
      <template #footer>
        <pm-Button
          label="Fechar"
          @click="actionModal(5)"
          class="p-button-danger"
        />
        <pm-Button label="Confirmar" @click="fechar()" />
      </template>
    </pm-Dialog>

    <pm-Dialog
      v-if="calSelec"
      :header="'Excluir ' + calSelec.nome"
      v-model:visible="showDialog7"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :maximizable="true"
    >
      <div>
        <span
        >Deseja excluir o calendário: {{ calSelec.nome }}?
          <b style="color: red">*(Essa ação não terá retorno)</b></span
        >
      </div>
      <template #footer>
        <pm-Button
          label="Fechar"
          @click="actionModal(7)"
          class="p-button-danger"
        />
        <pm-Button label="Confirmar" @click="excluir()" />
      </template>
    </pm-Dialog>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Calendario } from "@/class/calendario";
import { LoginInfo } from "@/class/login";
import { Verificar } from "@/class/verificar.js";
import { Configuracao } from "@/class/configuracao";
import calendarioComponent from "@/components/calendario/calendario.vue";

export default defineComponent({
  components: {
    calendarioComponent,
  },
  data() {
    return {
      ativar_calendario:0,
      ehDoTipoEja: false,
      semestreSelecionado:"1",
      jaDefinidoConfiguracoes:0,
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Calendário" }],
      isLoading: false,
      ativo: true,
      item: [],
      ano: null,
      perPage: 5,
      currentPage: 1,
      acesso: {
        visualizar: 1,
        cadastrar: 1,
        editar: 1,
        deletar: 1,
      },
      legenda: [
        {
          colorpicker: "",
          periodo: "1° período",
        },
        {
          colorpicker: "",
          periodo: "2° período",
        },
        {
          colorpicker: "",
          periodo: "3° período",
        },
        {
          colorpicker: "",
          periodo: "4° período",
        },
      ],
      configuracao: [],
      user: "",

      calendario_load: 0,
      meses: [
        { numero: "01", palavra: "janeiro" },
        { numero: "02", palavra: "fevereiro" },
        { numero: "03", palavra: "marco" },
        { numero: "04", palavra: "abril" },
        { numero: "05", palavra: "maio" },
        { numero: "06", palavra: "junho" },
        { numero: "07", palavra: "julho" },
        { numero: "08", palavra: "agosto" },
        { numero: "09", palavra: "setembro" },
        { numero: "10", palavra: "outubro" },
        { numero: "11", palavra: "novembro" },
        { numero: "12", palavra: "dezembro" },
      ],
      segmentos: [],
      listaSegmentos: [],

      configuracao: {
        calendario_letivos_id: 0,
        tipo_ano: "Bimestre",
        tipo_ano_nome_exib: "Período",
        data_inicial_matricula: null,
        data_final_matricula: null,

        b1_inicio: null,
        b1_final: null,
        b2_inicio: null,
        b2_final: null,
        b3_inicio: null,
        b3_final: null,
        b4_inicio: null,
        b4_final: null,
        test: null,

        colorpicker_1b: "2279e5",
        colorpicker_2b: "2279e5",
        colorpicker_3b: "2279e5",
        colorpicker_4b: "2279e5",
      },
      validationErrors: {},
      confiValidation: {
        finalUmOk: true,
        finalDoisOk: true,
        finalTresOk: true,
        finalQuatroOk: true,

        //pode passar para a proxima selecao
        primeiraSelecaoOk: false,
        segundaSelecaoOk: false,
        terceiraSelecaoOk: false,
        quartaSelcaoOk: false,

        //A selecao foi feita corretamente
        primeiraSelecaoInicioOk: -1,
        primeiraSelecaoFinalOk: -1,

        segundaSelecaoInicioOk: -1,
        segundaSelecaoFinalOk: -1,

        terceiraSelecaoInicioOk: -1,
        terceiraSelecaoFinalOk: -1,

        QuartaSelecaoInicioOk: -1,
        QuartaSelecaoFinalOk: -1,
      },
      validationErrors: {},
      confiValidation: {
        finalUmOk: true,
        finalDoisOk: true,
        finalTresOk: true,
        finalQuatroOk: true,

        //pode passar para a proxima selecao
        primeiraSelecaoOk: false,
        segundaSelecaoOk: false,
        terceiraSelecaoOk: false,
        quartaSelcaoOk: false,

        //A selecao foi feita corretamente
        primeiraSelecaoInicioOk: -1,
        primeiraSelecaoFinalOk: -1,

        segundaSelecaoInicioOk: -1,
        segundaSelecaoFinalOk: -1,

        terceiraSelecaoInicioOk: -1,
        terceiraSelecaoFinalOk: -1,

        QuartaSelecaoInicioOk: -1,
        QuartaSelecaoFinalOk: -1,
      },
      aConfiguracaoEstaOk: -1,

      calSelec: null,
      todosCalendarios: [],
      calFiltrado: null,
      showDialog: false,
      showDialog2: false,
      showDialog3: false,
      showDialog4: false,
      showDialog5: false,
      showDialog6: false,
      showDialog7: false,
      filtroDoCalendario: "1",
      novaConfigCal: null,
      novoCal: {
        nome: "",
        ano: 0,
      },
      disabled: false,
      listaSegmentosDisponivies: [],
      theAno: "",
      anoAtual: "",
      anoSitucao: 0,
      anoSituacaoAlterar:0
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.item.length / this.perPage)
        : this.item.length;
    },

    item() {
      this.item = [];
      return this.item;
      //listarTudo();
    },
  },

  methods: {
    atualizarAnoSituacao() {
      try {
        sessionStorage.setItem("anoSitucao", this.anoSituacaoAlterar ? 1 : 0);
        location.reload();
      } 
      catch (error) {
        console.error("Error updating year situation:", error);
      }
    },
    ejaInput() {
      if (this.ehDoTipoEja == true) {
        this.configuracao.b1_inicio = '';
        this.configuracao.b1_final = '';
        this.configuracao.b2_inicio = '';
        this.configuracao.b2_final = '';
        this.configuracao.b3_inicio =  new Date(4500, 1, 1);
        this.configuracao.b3_final =   new Date(4500, 1, 2);
        this.configuracao.b4_inicio =  new Date(4500, 1, 3);
        this.configuracao.b4_final =   new Date(4500, 1, 4);
      }
      else {
        this.configuracao.b1_inicio = '';
        this.configuracao.b1_final = '';
        this.configuracao.b2_inicio = '';
        this.configuracao.b2_final = '';
        this.configuracao.b3_inicio = '';
        this.configuracao.b3_final =  '';
        this.configuracao.b4_inicio =  '';
        this.configuracao.b4_final =   '';
      }
    },
    infoShow() {
      this.ehDoTipoEja = false;
      if (this.calSelec.b1_inicio == null && this.calSelec.b3_inicio == null) {
        this.jaDefinidoConfiguracoes = 0;
        this.semestreSelecionado = '1';
        return false;
      }
      else {
        let ano1 = this.calSelec.b1_inicio.split('-');
        ano1 = ano1[0];
        let ano2 = this.calSelec.b3_inicio.split('-');
        ano2 = ano2[0];

        //se o ano for 1500, significar que ele selecionou eja do tipo dois e vai colocar(3 a 4 periodo)
        if (ano1 == '1500') {
          this.ehDoTipoEja = true;
          this.semestreSelecionado = '2';
        }
        //se o ano for 4500, significar que ele selecionou eja do tipo dois e vai colocar(1 a 2 periodo)
        else if (ano2 == '4500') {
          this.ehDoTipoEja = true;
          this.semestreSelecionado = '1';
        }
        this.jaDefinidoConfiguracoes = 1;

        return true;
      }
    },
    apenasUmSemestre(tipo) {
      if (tipo == 1) {
          this.configuracao.b1_inicio = '';
          this.configuracao.b1_final = '';
          this.configuracao.b2_inicio = '';
          this.configuracao.b2_final = '';
          //adicionar uma data bem grande pros valores nao usados
          this.configuracao.b3_inicio =  new Date(4500, 1, 1);
          this.configuracao.b3_final =   new Date(4500, 1, 2);
          this.configuracao.b4_inicio =  new Date(4500, 1, 3);
          this.configuracao.b4_final =   new Date(4500, 1, 4);
      }
      if (tipo == 2) {
        //adicioanar uma data bem pequena pros valores inicias
        this.configuracao.b1_inicio = new Date(1500, 1, 1);
        this.configuracao.b1_final =  new Date(1500, 1, 1);
        this.configuracao.b2_inicio = new Date(1500, 1, 3);
        this.configuracao.b2_final =  new Date(1500, 1, 4);
        //limpar os valores grandes
        this.configuracao.b3_inicio = '';
        this.configuracao.b3_final =  '';
        this.configuracao.b4_inicio = '';
        this.configuracao.b4_final = ''
      }
    },
    async excluir() {
      try {
        const data = await Calendario.excluir(this.calSelec.id);
        if (data.data.id) {
          await this.carregarListarTudo();
          this.calSelec = null;
          this.actionModal(7);
          this.$vaToast.init({
            message: "Calendário Excluído!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3000,
            fullWidth: false,
          });
        } else if (data.data.status === 422) {
          this.$vaToast.init({
            message: data.data.erro,
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3000,
            color: "danger",
            fullWidth: false,
          });
        }
      } catch (error) {

      }
    },
    async buscarSegmentosDisponiveis() {
      const novo = {
        ano: this.calSelec.ano,
      };
      this.listaSegmentosDisponivies = data.data;
      this.actionModal(3);
    },
    onDisabled() {
      if (this.calSelec.situacao === 0) {
        this.disabled = true;
        this.acesso.editar = 0;
        this.acesso.cadastrar = 0;
        this.acesso.deletar = 0;
      } else {
        this.disabled = false;
        this.acesso.editar = 1;
        this.acesso.cadastrar = 1;
        this.acesso.deletar = 1;
      }
    },
    async filtrarCalendarios() {
      const todosCalendarios = await Calendario.buscarTodosCalendarios(
        this.TheAno
      );

      if (this.filtroDoCalendario == "1")
        {this.todosCalendarios = todosCalendarios.data;}
      else if (this.filtroDoCalendario == "2")
        {this.todosCalendarios = todosCalendarios.data.filter(
          (info) => info.situacao == 1
        );}
      else if (this.filtroDoCalendario == "3")
        {this.todosCalendarios = todosCalendarios.data.filter(
          (info) => info.situacao == 0
        );}
    },
    updateCalendar() {
      for (const el of this.todosCalendarios) {
        if (el.id == this.calSelec.id) {
          this.calSelec = el;
          break;
        }
      }
    },
    //função pra renderizar algo novo sem dar reload na page
    forceRerender(data) {
      //forçar as cores novas
      this.calSelec.colorpicker_1b = data.colorpicker_1b;
      this.calSelec.colorpicker_2b = data.colorpicker_2b;
      this.calSelec.colorpicker_3b = data.colorpicker_3b;
      this.calSelec.colorpicker_4b = data.colorpicker_4b;

      this.configuracao.colorpicker_1b = data.colorpicker_1b;
      this.configuracao.colorpicker_2b = data.colorpicker_2b;
      this.configuracao.colorpicker_3b = data.colorpicker_3b;
      this.configuracao.colorpicker_4b = data.colorpicker_4b;

      this.legenda[0].colorpicker = data.colorpicker_1b;
      this.legenda[1].colorpicker = data.colorpicker_2b;
      this.legenda[2].colorpicker = data.colorpicker_3b;
      this.legenda[3].colorpicker = data.colorpicker_4b;
    },
    async buscarDados() {
      if (
        this.calSelec.b1_inicio != null &&
        this.calSelec.b1_final != null &&
        this.calSelec.b2_inicio != null &&
        this.calSelec.b2_final != null &&
        this.calSelec.b3_inicio != null &&
        this.calSelec.b3_final != null &&
        this.calSelec.b4_inicio != null &&
        this.calSelec.b4_final != null
      ) {
        this.configuracao.b1_inicio = new Date(
          this.calSelec.b1_inicio
        ).toLocaleDateString("pt-BR");
        this.configuracao.b1_final = new Date(
          this.calSelec.b1_final
        ).toLocaleDateString("pt-BR");

        this.configuracao.b2_inicio = new Date(
          this.calSelec.b2_inicio
        ).toLocaleDateString("pt-BR");
        this.configuracao.b2_final = new Date(
          this.calSelec.b2_final
        ).toLocaleDateString("pt-BR");

        this.configuracao.b3_inicio = new Date(
          this.calSelec.b3_inicio
        ).toLocaleDateString("pt-BR");
        this.configuracao.b3_final = new Date(
          this.calSelec.b3_final
        ).toLocaleDateString("pt-BR");

        this.configuracao.b4_inicio = new Date(
          this.calSelec.b4_inicio
        ).toLocaleDateString("pt-BR");
        this.configuracao.b4_final = new Date(
          this.calSelec.b4_final
        ).toLocaleDateString("pt-BR");
      } else {
        this.configuracao.b1_final = null;
        this.configuracao.b1_inicio = null;
        this.configuracao.b2_final = null;
        this.configuracao.b2_inicio = null;
        this.configuracao.b3_final = null;
        this.configuracao.b3_inicio = null;
        this.configuracao.b4_final = null;
        this.configuracao.b4_inicio = null;
      }

      this.configuracao.colorpicker_1b = this.calSelec.colorpicker_1b;
      this.configuracao.colorpicker_2b = this.calSelec.colorpicker_2b;
      this.configuracao.colorpicker_3b = this.calSelec.colorpicker_3b;
      this.configuracao.colorpicker_4b = this.calSelec.colorpicker_4b;
    },
    async segFilter() {
      const novo = {
        ano: this.calSelec.ano,
      };
      const segAll = await Calendario.listaDeSegmentosDisponiveis(novo);
      this.segmentos = segAll.data;

      //filtrar os segmentos que não tem calendário relacionado
      const filter = this.segmentos.filter(
        (el) => el.relacionado_a_um_calendario != true
      );

      //caso o calendário selecionado não tenha segmentos relacionados, vai aparecer apenas os segmentos que não tem calendários relacionados
      if (this.calSelec.segmentos.length == 0) {
        this.segmentos = filter;
      } else if (this.calSelec.segmentos.length > 0) {
        //caso o calendário selecionado tenha segmentos relacionados, irá aparecer os segmentos relacionados a ele e não relacionados
        for (let i = 0; i < this.calSelec.segmentos.length; i++) {
          const segRelacionados = this.segmentos.find(
            (el) => el.id === this.calSelec.segmentos[i].segmento_id
          );
          if (segRelacionados != undefined) {
            filter.push(segRelacionados);
          }
        }
        this.segmentos = filter;
      }
    },
    async selectCal() {
      this.onDisabled();
      await this.segFilter();
      await this.buscarDados();
      if (this.calSelec.segmentos.length > 0) {
        this.listaSegmentos = [];
        for (let i = 0; i < this.calSelec.segmentos.length; i++) {
          const found = this.segmentos.find(
            (element) => element.id === this.calSelec.segmentos[i].segmento_id
          );
          if (found != undefined) {
            this.listaSegmentos.push(found);
          }
        }
      }
      this.infoShow();
      //todos os periodos
      if (!this.ehDoTipoEja) {
        this.legenda = [];
        for (let i = 0; i < 4; i++) {
          const novo = {
            periodo : '',
            colorpicker : ''
          }
          this.legenda.push(novo);
        }
        this.legenda[0].periodo = "1º Período";
        this.legenda[1].periodo = "2º Período";
        this.legenda[2].periodo = "3º Período";
        this.legenda[3].periodo = "4º Período";
        this.legenda[0].colorpicker = this.calSelec.colorpicker_1b;
        this.legenda[1].colorpicker = this.calSelec.colorpicker_2b;
        this.legenda[2].colorpicker = this.calSelec.colorpicker_3b;
        this.legenda[3].colorpicker = this.calSelec.colorpicker_4b;
      }
      //apenas o 1 e 2
      else if (this.ehDoTipoEja && this.semestreSelecionado == '1') {
        this.legenda = [];
        for (let i = 0; i < 2; i++) {
          const novo = {
            periodo : '',
            colorpicker : ''
          }
          this.legenda.push(novo);
        }
        this.legenda[0].periodo = "1º Período";
        this.legenda[1].periodo = "2º Período";

        this.legenda[0].colorpicker = this.calSelec.colorpicker_1b;
        this.legenda[1].colorpicker = this.calSelec.colorpicker_2b;

      }
      //apenas o 3 e 4
      else {
        this.legenda = [];
        for (let i = 0; i < 2; i++) {
          const novo = {
            periodo : '',
            colorpicker : ''
          }
          this.legenda.push(novo);
        }
        this.legenda[0].periodo = "3º Período";
        this.legenda[1].periodo = "4º Período";

        this.legenda[0].colorpicker = this.calSelec.colorpicker_3b;
        this.legenda[1].colorpicker = this.calSelec.colorpicker_4b;
      }
      //receber as colores do period
    },
    actionModal(modal) {
      if (modal == 1) {
        this.showDialog = !this.showDialog;
      }
      if (modal == 2) {
        this.showDialog2 = !this.showDialog2;
      }
      if (modal == 3) {
        this.showDialog3 = !this.showDialog3;
      }
      if (modal == 4) {
        this.showDialog4 = !this.showDialog4;
      }
      if (modal == 5) {
        this.showDialog5 = !this.showDialog5;
      }
      if (modal == 6) {
        this.showDialog6 = !this.showDialog6;
      }
      if (modal == 7) {
        this.showDialog7 = !this.showDialog7;
      }
    },
    async criarCal() {
      try {
        const data = await Calendario.cadastrar(this.novoCal);
        if (data.status == 201) {
          this.actionModal(1);
          this.novoCal.nome = "";
          this.novoCal.ano = this.anoAtual;

          this.listarTudo();
          this.$vaToast.init({
            message: "Calendário criado!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3000,
            fullWidth: false,
          });
        }
      } catch (error) {
        this.$toast.error(); //botar o retorno da msg;
      }
      //test
    },
    procurarCal(event) {
      const query = event.query;
      const filteredItems = [];
      for (let i = 0; i < this.todosCalendarios.length; i++) {
        // if (this.todosCalendarios[i].status == true) {
        const cal = this.todosCalendarios[i];
        if (cal.nome.toLowerCase().indexOf(query.toLowerCase()) != -1) {
          filteredItems.push(cal);
        }
        // }
      }
      this.calFiltrado = filteredItems;
    },
    converterParaFormatoUSA(date) {
      if (date instanceof Date) {
        return date.toISOString().split("T")[0];
      }
      const dataQuebrada = date.split("/");
      let dia = dataQuebrada[0];
      let mes = dataQuebrada[1];
      const ano = dataQuebrada[2];
      if (dia.length == 1) {dia = "0" + dia;}

      if (mes.length == 1) {mes = "0" + mes;}

      return ano + "-" + mes + "-" + dia;
    },
    async configCal(configCal) {
      let novo;
      if (this.configuracao.b1_inicio != null) {
        novo = {
          calendario_letivos_id: this.calSelec.id,
          ano: this.calSelec.ano,
          nome: this.calSelec.nome,
          b1_inicio: this.converterParaFormatoUSA(this.configuracao.b1_inicio),
          b1_final: this.converterParaFormatoUSA(this.configuracao.b1_final),
          b2_inicio: this.converterParaFormatoUSA(this.configuracao.b2_inicio),
          b2_final: this.converterParaFormatoUSA(this.configuracao.b2_final),
          b3_inicio: this.converterParaFormatoUSA(this.configuracao.b3_inicio),
          b3_final: this.converterParaFormatoUSA(this.configuracao.b3_final),
          b4_inicio: this.converterParaFormatoUSA(this.configuracao.b4_inicio),
          b4_final: this.converterParaFormatoUSA(this.configuracao.b4_final),

          colorpicker_1b: this.configuracao.colorpicker_1b,
          colorpicker_2b: this.configuracao.colorpicker_2b,
          colorpicker_3b: this.configuracao.colorpicker_3b,
          colorpicker_4b: this.configuracao.colorpicker_4b,
        };
      } else {
        this.$vaToast.init({
          message: "Preencha todos os campos!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3000,
          fullWidth: false,
          color: "warning",
        });
        return false;
        // novo = {
        //   calendario_letivos_id: this.calSelec.id,
        //   ano: this.calSelec.ano,
        //   nome: this.calSelec.nome,
        //   colorpicker_1b: this.configuracao.colorpicker_1b,
        //   colorpicker_2b: this.configuracao.colorpicker_2b,
        //   colorpicker_3b: this.configuracao.colorpicker_3b,
        //   colorpicker_4b: this.configuracao.colorpicker_4b,
        // };
      }
      const data = await Calendario.alterar(novo);
      location.reload();
      // await this.carregarListarTudo();
      // this.updateCalendar();
      // this.forceRerender(data.data);
      // this.$vaToast.init({
      //   message: "Salvo com sucesso!",
      //   iconClass: "fa-star-o",
      //   position: "top-right",
      //   duration: 3000,
      //   fullWidth: false,
      //   color: "success",
      // });
      // this.actionModal(2);
    },

    async linkarCal() {
      const novo = {
        calendario_letivo_id: this.calSelec.id,
        listaSegmentos: this.listaSegmentos.length ? this.listaSegmentos : [],
        ano: this.TheAno,
      };
      const data = await Calendario.linkarVariosSegmentosAoCalendario(novo);
      if (data.status === 200) {
        this.$vaToast.init({
          message: "Segmentos ligados ao calendário com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3000,
          fullWidth: false,
        });
      } else {
        this.$vaToast.init({
          message: "Houve um problema ao ligar os segmentos ao calendário!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3000,
          fullWidth: false,
          color: "danger",
        });
      }
      const todosCalendarios = await Calendario.buscarTodosCalendarios(
        this.TheAno
      );
      this.todosCalendarios = todosCalendarios.data;
      this.actionModal(3);
    },
    async fecharTodos() {
      this.isLoading = true;
      if (this.configuracao.autorizado == 1) {
        const data = await Calendario.fechar(1, 1);
        this.$vaToast.init({
          message: "Ano letivo fechado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3000,
          fullWidth: false,
          color: "success"
        });
        this.calSelec = null;
        this.isLoading = false;

        this.$router.push({ name: "login" });

       // await this.carregarListarTudo();
       // this.actionModal(4);
      } else {
        this.$vaToast.init({
          message: "Não pode finalizar o ano sem uma configuração autorizada",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3000,
          fullWidth: false,
          color: "danger",
        });
      }
      this.isLoading = false;
    },
    verificarSeEhLetivo(mes, periodo, inicioOuFinal) {
      mes = mes.toLocaleDateString("sv-SE");
      mes = mes.split("-");
      let dia = mes[2];
      mes = mes[1];
      const found1 = this.meses.find((element) => element.numero == mes);

      const infoCalendario = this.calSelec[found1.palavra];

      if (dia.length == 2 && dia[0] == "0") {dia = dia.slice(1);}
      const found2 = infoCalendario.find((element) => element.dia == dia);
      //primeiro periodo inicio
      if (
        (found2 == undefined || found2.marcado == "0") &&
        periodo == 1 &&
        inicioOuFinal == "inicio"
      ) {
        this.validationErrors["b1_inicio"] = true;
        this.aConfiguracaoEstaOk = 0;
        return false;
      } else if (periodo == 1 && inicioOuFinal == "inicio") {
        delete this.validationErrors["b1_inicio"];
      }
      //primeiro periodo final
      if (
        (found2 == undefined || found2.marcado == "0") &&
        periodo == 1 &&
        inicioOuFinal == "final"
      ) {
        this.validationErrors["b1_final"] = true;
        this.aConfiguracaoEstaOk = 0;
        return false;
      } else if (periodo == 1 && inicioOuFinal == "final") {
        delete this.validationErrors["b1_final"];
      }
      //segundo periodo inicio
      if (
        (found2 == undefined || found2.marcado == "0") &&
        periodo == 2 &&
        inicioOuFinal == "inicio"
      ) {
        this.validationErrors["b2_inicio"] = true;
        this.aConfiguracaoEstaOk = 0;
        return false;
      } else if (periodo == 2 && inicioOuFinal == "inicio") {
        delete this.validationErrors["b2_inicio"];
      }
      //segundo periodo final
      if (
        (found2 == undefined || found2.marcado == "0") &&
        periodo == 2 &&
        inicioOuFinal == "final"
      ) {
        this.validationErrors["b2_final"] = true;
        this.aConfiguracaoEstaOk = 0;
        return false;
      } else if (periodo == 2 && inicioOuFinal == "final") {
        delete this.validationErrors["b2_final"];
      }
      //terceiro periodo inicio
      if (
        (found2 == undefined || found2.marcado == "0") &&
        periodo == 3 &&
        inicioOuFinal == "inicio"
      ) {
        this.validationErrors["b3_inicio"] = true;
        this.aConfiguracaoEstaOk = 0;
        return false;
      } else if (periodo == 3 && inicioOuFinal == "inicio") {
        delete this.validationErrors["b3_inicio"];
      }
      //terceiro periodo final
      if (
        (found2 == undefined || found2.marcado == "0") &&
        periodo == 3 &&
        inicioOuFinal == "final"
      ) {
        this.validationErrors["b3_final"] = true;
        this.aConfiguracaoEstaOk = 0;
        return false;
      } else if (periodo == 3 && inicioOuFinal == "final") {
        delete this.validationErrors["b3_final"];
      }
      //quarto periodo inicio
      if (
        (found2 == undefined || found2.marcado == "0") &&
        periodo == 4 &&
        inicioOuFinal == "inicio"
      ) {
        this.validationErrors["b4_inicio"] = true;
        this.aConfiguracaoEstaOk = 0;
        return false;
      } else if (periodo == 4 && inicioOuFinal == "inicio") {
        delete this.validationErrors["b4_inicio"];
      }
      //quarto periodo final
      if (
        (found2 == undefined || found2.marcado == "0") &&
        periodo == 4 &&
        inicioOuFinal == "final"
      ) {
        this.validationErrors["b4_final"] = true;
        this.aConfiguracaoEstaOk = 0;
        return false;
      } else if (periodo == 4 && inicioOuFinal == "final") {
        delete this.validationErrors["b4_final"];
        this.aConfiguracaoEstaOk = 1;
      }

      if (inicioOuFinal == "final")
        {this.verificarDistanciaDeUmInicioParaUmFinal(periodo);}

      this.verificarDistanciaDeUmPeriodoParaOutro(inicioOuFinal, periodo);
    },
    verificarDistanciaDeUmInicioParaUmFinal(periodo) {
      if (periodo == 1) {
        if (this.configuracao.b1_inicio >= this.configuracao.b1_final) {
          this.confiValidation.finalUmOk = false;
          this.aConfiguracaoEstaOk = 0;
          return false;
        } else {
          this.confiValidation.finalUmOk = true;
          this.confiValidation.primeiraSelecaoOk = true;
        }
      } else if (periodo == 2) {
        if (this.configuracao.b2_inicio >= this.configuracao.b2_final) {
          this.confiValidation.finalDoisOk = false;
          this.aConfiguracaoEstaOk = 0;
          return false;
        } else {
          this.confiValidation.finalDoisOk = true;
          this.confiValidation.segundaSelecaoOk = true;
        }
      } else if (periodo == 3) {
        if (this.configuracao.b3_inicio >= this.configuracao.b3_final) {
          this.confiValidation.finalTresOk = false;
          this.aConfiguracaoEstaOk = 0;
          return false;
        } else {
          this.confiValidation.finalTresOk = true;
          this.confiValidation.terceiraSelecaoOk = true;
        }
      } else if (periodo == 4) {
        if (this.configuracao.b4_inicio >= this.configuracao.b4_final) {
          this.confiValidation.finalQuatroOk = false;
          this.aConfiguracaoEstaOk = 0;
          return false;
        } else {
          this.confiValidation.finalQuatroOk = true;
          this.confiValidation.QuatroSelecaoOk = true;
          this.aConfiguracaoEstaOk = 1;
        }
      }
    },
    verificarDistanciaDeUmPeriodoParaOutro(inicioOuFinal, periodo) {
      if (periodo == 2) {
        if (inicioOuFinal == "inicio") {
          if (
            this.configuracao.b2_inicio >= this.configuracao.b1_inicio &&
            this.configuracao.b2_inicio >= this.configuracao.b1_final
          ) {
            this.confiValidation.segundaSelecaoInicioOk = 1;
          } else {
            this.confiValidation.segundaSelecaoInicioOk = 0;
            this.aConfiguracaoEstaOk = 0;
          }
        } else if (inicioOuFinal == "final") {
          if (
            this.configuracao.b2_final >= this.configuracao.b1_inicio &&
            this.configuracao.b2_final >= this.configuracao.b1_final
          ) {
            this.confiValidation.segundaSelecaoFinalOk = 1;
          } else {
            this.confiValidation.segundaSelecaoFinalOk = 0;
            this.aConfiguracaoEstaOk = 0;
          }
        }
      } else if (periodo == 3) {
        if (inicioOuFinal == "inicio") {
          if (
            this.configuracao.b3_inicio >= this.configuracao.b1_inicio &&
            this.configuracao.b3_inicio >= this.configuracao.b1_final
          ) {
            this.confiValidation.terceiraSelecaoInicioOk = 1;
          } else {
            this.confiValidation.terceiraSelecaoInicioOk = 0;
            this.aConfiguracaoEstaOk = 0;
          }
          if (
            this.configuracao.b3_inicio >= this.configuracao.b2_inicio &&
            this.configuracao.b3_inicio >= this.configuracao.b2_final
          ) {
            this.confiValidation.terceiraSelecaoInicioOk = 1;
          } else {
            this.confiValidation.terceiraSelecaoInicioOk = 0;
            this.aConfiguracaoEstaOk = 0;
          }
        } else if (inicioOuFinal == "final") {
          if (
            this.configuracao.b3_final >= this.configuracao.b1_inicio &&
            this.configuracao.b3_final >= this.configuracao.b1_final
          ) {
            this.confiValidation.terceiraSelecaoFinalOk = 1;
          } else {
            this.confiValidation.terceiraSelecaoFinalOk = 0;
            this.aConfiguracaoEstaOk = 0;
          }
          if (
            this.configuracao.b3_final >= this.configuracao.b2_inicio &&
            this.configuracao.b3_final >= this.configuracao.b2_final
          ) {
            this.confiValidation.terceiraSelecaoFinalOk = 1;
          } else {
            this.confiValidation.terceiraSelecaoFinalOk = 0;
            this.aConfiguracaoEstaOk = 0;
          }
        }
      } else if (periodo == 4) {
        if (inicioOuFinal == "inicio") {
          if (
            this.configuracao.b4_inicio >= this.configuracao.b1_inicio &&
            this.configuracao.b4_inicio >= this.configuracao.b1_final
          ) {
            this.confiValidation.QuartaSelecaoInicioOk = 1;
          } else {
            this.confiValidation.QuartaSelecaoInicioOk = 0;
            this.aConfiguracaoEstaOk = 0;
          }
          if (
            this.configuracao.b4_inicio >= this.configuracao.b2_inicio &&
            this.configuracao.b4_inicio >= this.configuracao.b2_final
          ) {
            this.confiValidation.QuartaSelecaoInicioOk = 1;
          } else {
            this.confiValidation.QuartaSelecaoInicioOk = 0;
            this.aConfiguracaoEstaOk = 0;
          }
          if (
            this.configuracao.b4_inicio >= this.configuracao.b3_inicio &&
            this.configuracao.b4_inicio >= this.configuracao.b3_final
          ) {
            this.confiValidation.QuartaSelecaoInicioOk = 1;
          } else {
            this.confiValidation.QuartaSelecaoInicioOk = 0;
            this.aConfiguracaoEstaOk = 0;
          }
        } else if (inicioOuFinal == "final") {
          if (
            this.configuracao.b4_final >= this.configuracao.b1_inicio &&
            this.configuracao.b4_final >= this.configuracao.b1_final
          ) {
            this.confiValidation.QuartaSelecaoFinalOk = 1;
          } else {
            this.confiValidation.QuartaSelecaoFinalOk = 0;
            this.aConfiguracaoEstaOk = 0;
          }
          if (
            this.configuracao.b4_final >= this.configuracao.b2_inicio &&
            this.configuracao.b4_final >= this.configuracao.b2_final
          ) {
            this.confiValidation.QuartaSelecaoFinalOk = 1;
          } else {
            this.aConfiguracaoEstaOk = 0;
            this.confiValidation.QuartaSelecaoFinalOk = 0;
          }

          if (
            this.configuracao.b4_final >= this.configuracao.b2_inicio &&
            this.configuracao.b4_final >= this.configuracao.b3_final
          ) {
            this.confiValidation.QuartaSelecaoFinalOk = 1;
            this.aConfiguracaoEstaOk = 1;
          } else {
            this.confiValidation.QuartaSelecaoFinalOk = 0;
            this.aConfiguracaoEstaOk = 0;
          }
        }
      }
    },
    verificarDatasPeriodos(tipo) {
      this.aConfiguracaoEstaOk = 1;
      if (this.validationErrors.b1_inicio) {return false;}

      if (this.validationErrors.b1_final) {return false;}

      if (this.validationErrors.b2_inicio) {return false;}

      if (this.validationErrors.b2_final) {return false;}

      if (this.validationErrors.b3_inicio) {return false;}

      if (this.validationErrors.b3_final) {return false;}

      if (this.validationErrors.b4_inicio) {return false;}

      if (this.validationErrors.b4_final) {return false;}

      if (!this.confiValidation.finalUmOk) {return false;}

      if (this.confiValidation.segundaSelecaoInicioOk == 0) {return false;}

      if (!this.confiValidation.finalDoisOk) {return false;}

      if (this.confiValidation.segundaSelecaoFinalOk == 0) {return false;}

      if (this.confiValidation.terceiraSelecaoInicioOk == 0) {return false;}

      if (!this.confiValidation.finalTresOk) {return false;}

      if (this.confiValidation.terceiraSelecaoFinalOk == 0) {return false;}

      if (this.confiValidation.QuartaSelecaoInicioOk == 0) {return false;}

      if (!this.confiValidation.finalQuatroOk) {return false;}

      if (!this.confiValidation.QuartaSelecaoFinalOk) {return false;}
      return true;
    },

    async start() {
      try {
        this.isLoading = true;
        const token = sessionStorage.getItem("token");
        const login = await LoginInfo.WhoIam(token);

        const novo = {
          user_id: login.data.login_id,
        };

        const data = await Calendario.start(novo);


        if (this.ativo == false) {
          sessionStorage.removeItem("anoSelecionado");
          sessionStorage.removeItem("anoSituacao");
          sessionStorage.setItem("anoSelecionado", data.data.ano);
          sessionStorage.setItem("anoSitucao", 1);
          window.location.reload();
          this.isLoading = false;
        }

        this.listarTudo();
        window.location.reload();
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        alert(
          "Não Pode Iniciar um Novo Ano letivo, Enquanto não Fechar esse Ano!"
        );
      }
    },
    async fechar() {
      if (this.configuracao.autorizado == 1) {
        const data = await Calendario.fechar(this.calSelec.id, 0);
        this.$vaToast.init({
          message: `Calendário ${this.calSelec.nome} foi fechado!`,
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3000,
          fullWidth: false,
        });
        this.calSelec = null;
        await this.carregarListarTudo();
        this.actionModal(5);
      } else {
        this.$vaToast.init({
          message: "Não pode finalizar o ano sem uma configuração autorizada",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3000,
          fullWidth: false,
          color: "danger",
        });
      }
    },

    async ativarCalendario() {
      try {
        const data = await Calendario.ativarCalendario(this.item.id);
        this.listarTudo();
      } catch (e) {}
    },
    async desativarCalendario() {
      try {
        const data = await Calendario.desativarCalendario(this.item.id);
        this.listarTudo();
      } catch (e) {}
    },

    mes__verif(mes) {
      const arr = [
        "janeiro",
        "fevereiro",
        "marco",
        "abril",
        "maio",
        "junho",
        "julho",
        "agosto",
        "setembro",
        "outubro",
        "novembro",
        "dezembro",
      ];
      for (let i = 0; i <= 12; i++) {
        if (mes == arr[i]) {
          let r = i + 1;
          if (r < 10) {
            r = "0" + r;
          }
          return r;
        }
      }
    },

    verificar_antes_1periodo(mes, dia) {
      const inicio_b1 = this.configuracao.b1_inicio.split(" ");
      const inicio_b1_p = inicio_b1[0].split("-");

      let dia_formatado = "";
      if (dia < 10) {
        dia_formatado = "0" + dia;
      } else {
        dia_formatado = dia;
      }

      const mes_formatado = this.mes__verif(mes);

      if (inicio_b1_p[1] == mes_formatado) {
        if (dia_formatado < inicio_b1_p[2]) {
          this.$vaToast.init({
            message: "Dia não Permitido!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3000,
            fullWidth: false,
          });
          return false;
        }
      } else if (mes_formatado < inicio_b1_p[1]) {
        this.$vaToast.init({
          message: "Dia não Permitido!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3000,
          fullWidth: false,
        });
        return false;
      }
      return true;
    },

    verificar_depois_4periodo(mes, dia) {
      const final_b4 = this.configuracao.b4_final.split(" ");
      const final_b4_p = final_b4[0].split("-");

      let dia_formatado = "";
      if (dia < 10) {
        dia_formatado = "0" + dia;
      } else {
        dia_formatado = dia;
      }

      const mes_formatado = this.mes__verif(mes);

      if (final_b4_p[1] == mes_formatado) {
        if (dia_formatado > final_b4_p[2]) {
          this.$vaToast.init({
            message: "Dia não Permitido!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3000,
            fullWidth: false,
          });
          return false;
        }
      } else if (mes_formatado > final_b4_p[1]) {
        this.$vaToast.init({
          message: "Dia não Permitido!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3000,
          fullWidth: false,
        });
        return false;
      }

      return true;
    },

    async marcar(mes, dia, marcado, calendario_letivo_id) {
      try {
        // if (marcado == 1) {
        //   //desmarcar dia
        //   const tudo = {
        //     dia: dia,
        //     mes: mes,
        //     user_id: 1,
        //     desmarcar: 1,
        //     calendario_letivo_id: calendario_letivo_id,
        //   };
        //   const data = await Calendario.marcarDesmarcar(tudo);
        //   await this.listarTudo();

        //   this.$vaToast.init({
        //     message: "Dia: " + dia + " do Mês: " + mes + " foi Desmarcado!",
        //     iconClass: "fa-star-o",
        //     position: "top-right",
        //     duration: 3000,
        //     fullWidth: false,
        //   });
        // }
        // if (marcado == 0) {
        //   //trava na marcação letiva dos dias menores que o 1° periodo inicio
        //   const res = this.verificar_antes_1periodo(mes, dia);

        //   //trava na marcação letiva dos dias maiores que o 4° periodo final
        //   const res2 = this.verificar_depois_4periodo(mes, dia);
        //   if (res && res2) {
        //     // macar dia
        //     const tudo = {
        //       dia: dia,
        //       mes: mes,
        //       user_id: 1,
        //       desmarcar: 0,
        //       calendario_letivo_id: calendario_letivo_id,
        //     };

        //     const data = await Calendario.marcarDesmarcar(tudo);
        //     await this.listarTudo();

        //     this.$vaToast.init({
        //       message: "Dia: " + dia + " do Mês: " + mes + " foi Marcado!",
        //       iconClass: "fa-star-o",
        //       position: "top-right",
        //       duration: 3000,
        //       fullWidth: false,
        //     });
        //   }
        // }
        // this.call(1);
      } catch (e) {}
    },
    async configFaltas() {
      const payload = {
        porcent_creche_falta: this.item.porcent_creche_falta,
        porcent_fund1_falta: this.item.porcent_fund1_falta,
        porcent_outros_falta: this.item.porcent_outros_falta,
        simulado: this.item.simulado,
        atividades: this.item.atividades,
        avaliacao: this.item.avaliacao,
        media: this.item.media,
      };

      const data = await Calendario.calendarioEditarConfig(this.item.id, payload);
    },
    async listarTudo() {
      try {
        const todosCalendarios = await Calendario.buscarTodosCalendarios(
          this.TheAno
        );
        this.todosCalendarios = todosCalendarios.data;
        const data = await Calendario.calendarioAtual();
        if (!data.data.length) {
          //verificar se ja existe um calendario no sistema
          this.ativo = false;
        }

        const dt = await Configuracao.obtemOne(data.data.id);

        let count_periodo = 0;

        if (dt.data.length > 0 || dt.data.id) {
          this.configuracao = "";
          if (this.calendario_load == 0) {
            this.legenda = [];
            this.calendario_load = 1;
            let lg = [];

            if (count_periodo < 5) {
              lg = {
                colorpicker: dt.data.colorpicker_1b,
                periodo: "1° " + dt.data.tipo_ano_nome_exib,
              };
              this.legenda.push(lg);
              count_periodo = count_periodo + 1;
            }

            if (count_periodo < 5) {
              lg = [];
              lg = {
                colorpicker: dt.data.colorpicker_2b,
                periodo: "2° " + dt.data.tipo_ano_nome_exib,
              };

              this.legenda.push(lg);
              count_periodo = count_periodo + 1;
            }

            if (count_periodo < 5) {
              lg = [];
              lg = {
                colorpicker: dt.data.colorpicker_3b,
                periodo: "3° " + dt.data.tipo_ano_nome_exib,
              };

              this.legenda.push(lg);
              count_periodo = count_periodo + 1;
            }

            if (count_periodo < 5) {
              lg = [];
              lg = {
                colorpicker: dt.data.colorpicker_4b,
                periodo: "4° " + dt.data.tipo_ano_nome_exib,
              };

              this.legenda.push(lg);
              count_periodo = count_periodo + 1;
            }
          }

          this.configuracao = dt.data;
        }
        this.item = data.data;
        this.ano = this.item[0].ano;
        await this.buscarDados();
      } catch (e) {}
    },
    paginaConfig() {
      this.$router.push({ name: "config-calendario" });
    },

    //ativar configuração do calendário
    async ativarConfig() {
      const payload = {
        id: this.configuracao.id,
      };
      const data = await Configuracao.ativarConfiguracao(payload);
      if (data.status === 200 && data.data != "") {
        this.$vaToast.init({
          message: "Ativado com Sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3500,
          fullWidth: false,
          color: "success",
        });
        window.location.reload();
      } else if (data.data === "") {
        this.$vaToast.init({
          message: "Não tem nenhuma configuração geral cadastrada!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3500,
          fullWidth: false,
          color: "danger",
        });
      }
    },

    //faz com que o campo autorização volte para 0
    async permitirAtivarConfig() {

      const payload = {
        id: this.configuracao.id,
      };
      const data = await Configuracao.permitirAtivacaoConfiguracao(payload);

      this.$vaToast.init({
        message: "Permissão Concebida",
        iconClass: "fa-star-o",
        position: "top-right",
        duration: 3500,
        fullWidth: false,
        color: "success",
      });
      window.location.reload();
    },

    //função para chamar no beforeMount para rodar o loading apenas quando entrar na página
    async carregarListarTudo() {
      this.isLoading = true;
      await this.listarTudo();
      this.isLoading = false;
    },
    async call(update) {
      const todosCalendarios = await Calendario.buscarTodosCalendarios(
        this.TheAno
      );
      this.todosCalendarios = todosCalendarios.data;

      //se ele desmarcar ou marcar um dia letivo, atualizar o calendario selecionado
      if (update == 1) {
        for (const el of this.todosCalendarios) {
          if (el.id == this.calSelec.id) {
            this.calSelec = el;
            break;
          }
        }
      }
    },
  },
  async beforeMount() {
    const today = new Date();
    this.anoAtual = today.getFullYear();
    this.novoCal.ano = this.anoAtual;
    this.TheAno = parseInt(sessionStorage.getItem("anoSelecionado"));

    this.anoSitucao = sessionStorage.getItem("anoSitucao");
    this.anoSituacaoAlterar = this.anoSitucao == 1 ? true : false;
    this.carregarListarTudo();
    const token = sessionStorage.getItem("token");
    const login = await LoginInfo.WhoIam(token);
    this.user = login;
    if(this.user.data.funcao == 1  || this.user.data.funcao == 2){
      this.ativar_calendario = 1;
    }

    const acesso = await Verificar.AcessoLocal(1);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
    const novo = {
      ano: this.TheAno,
    };
    const seg = await Calendario.listaDeSegmentosDisponiveis(novo);
    this.segmentos = seg.data;

  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>

<style>
.custom-message .p-tooltip-text {
  background-color: var(--blue-800);
  color: rgb(255, 255, 255);
}
.semana {
  background-color: rgb(239 239 239);
  text-align: center;
}
.font-semana {
  font-size: 10px;
}
.dias {
  text-align: center;
}
.dia-marcado {
  background-color: #4182c5 !important;
  color: #fff;
}
</style>
